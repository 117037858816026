import styled from "styled-components";

const Button = styled.button`
  width: ${({ width }) => width || "200px"};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height || "40px"};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border || "1px solid #EEF5DB"};
  background: ${({ background }) => background || "#EEF5DB"};
  border-radius: ${({ radius }) => radius || "2px"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  opacity: ${({ opacity }) => opacity || "1"};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${({ color }) => color || "#071E22"};
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize || "14px"};
  @media (max-width: 350px) {
    height: ${({ height }) => height === "68px" && "50px"};
  }
  :focus,
  :active {
    border: ${({ border }) => border || "1px solid #EEF5DB"};
    background: ${({ background }) => background || "#EEF5DB"};
  }
  svg {
    color: ${({ color }) => color || "#071E22"};
    margin: ${({ svgMargin }) => svgMargin || "0 0 -4px 0"};
    font-size: 18px;
  }
  :hover {
    background: ${({ hoverBg }) => hoverBg || "transparent"};
    color: ${({ hoverColor }) => hoverColor || "#EEF5DB"};
    svg {
      color: ${({ hoverColor }) => hoverColor || "#EEF5DB"};
    }
  }
`;
export default Button;
