import React, { useState, useEffect, useCallback, useContext } from "react";
import DashboardLayout from "../../../components/Layout/layout";
import { Section, FlexibleDiv } from "../../../components/Box/styles";
import { ScorecardWrap } from "./scorecard.styles";
import RatingStars from "../../../components/RatingStar/ratingStar";
import Button from "../../../components/Button";
import {
  FaLongArrowAltLeft as Prev,
  FaLongArrowAltRight as Next,
} from "react-icons/fa";
import {
  AiFillCloseCircle as Close,
  AiFillPlayCircle as Play,
  AiFillPlusSquare as Plus,
} from "react-icons/ai";
import AudioMic from "../../../assets/Img/ScorcardImages/audio.svg";
import CustomCheck from "../../../components/TickCheck/tick";
import {
  BsFacebook as Facebook,
  BsInstagram as Instagram,
} from "react-icons/bs";
import { SiTiktok as Tiktok } from "react-icons/si";
import { MdLibraryMusic as Music } from "react-icons/md";
import { FiEdit as Edit } from "react-icons/fi";
import CloseScoreCard from "./Modals/closeScorecardModal";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { getSingleProject, updateReview } from "../../../network/projects";
import GenreModal from "./Modals/genreModal";
import { MainContext } from "../../../context/MainContext";
import ApprovalReason from "./Modals/approvalReason";
import RejectionReason from "./Modals/rejectionReason";
import SubmitModal from "./Modals/submitDataModal";

const Scorecard = () => {
  const [showCloseCardModal, setShowCloseCardModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeCard, setchangeCard] = useState("");
  const navigate = useNavigate();
  const {
    state: { user, pageData },
  } = useContext(MainContext);
  const location = useLocation();
  const { state } = location;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [melody, setMelody] = useState();
  const [lyrics, setLyrics] = useState();
  const [project_score, setProjecScore] = useState();
  const [market_potential, setMarketPotential] = useState();
  const [readiness, setReadiness] = useState();
  const [revv_factor, setRevFactor] = useState();
  const [showGenreModal, setShowGenreModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [reviewer_genre, setReviewerGenre] = useState([]);
  const [review_status, setReviewStatus] = useState();
  const [reason_for_status, setReviewReason] = useState();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [next, setNext] = useState({});

  const showNextModal = () => {
    setShowCloseCardModal(true);
    setchangeCard("next");
  };
  const showPrevModal = () => {
    setShowCloseCardModal(true);
    setchangeCard("prev");
  };

  const handleCloseScorecard = () => {
    if (submitted === false) {
      setShowCloseCardModal(true);
      setchangeCard("");
    } else {
      navigate(-1);
    }
  };

  const getNextToGo = () => {
    pageData?.map((item, index) => {
      if (item._id === state._id) {
        return setNext({
          next: pageData[index + 1],
          prev: pageData[index - 1],
        });
      }
    });
  };

  const getData = useCallback(async () => {
    setIsLoading(true);
    if (state && state._id) {
      const response = await getSingleProject(state);
      getNextToGo();
      setData(response);
      setMelody(response?.review[0]?.scorecard?.melody);
      setMarketPotential(response?.review[0]?.scorecard?.market_potential);
      setReadiness(response?.review[0]?.scorecard?.readiness);
      setRevFactor(response?.review[0]?.scorecard?.revv_factor);
      setReviewReason(response?.review[0]?.scorecard?.reason_for_status);
      setReviewStatus(response?.review[0]?.scorecard?.review_status);
      setLyrics(response?.review[0]?.scorecard?.lyrics);
      setReviewerGenre(response?.review[0]?.scorecard?.reviewer_genre || []);
      setProjecScore(response?.review[0]?.scorecard?.project_score);
      if (response?.review[0]?.review_status !== "Pending") {
        setSubmitted(true);
      }
      setIsLoading(false);
    } else {
      navigate(-1);
    }
  }, [state, setData, setIsLoading]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleNextScoreCard = () => {
    setchangeCard("");
    setSubmitted(false);
    setEdit(false);
    navigate(`/project/${next?.next?._id}`, { state: next?.next });
  };
  const handlePrevScoreCard = () => {
    setchangeCard("");
    setSubmitted(false);
    setEdit(false);
    navigate(`/project/${next?.prev?._id}`, { state: next?.prev });
  };

  const handleReason = (e) => {
    if (e === review_status) {
      setReviewReason(reason_for_status);
    } else {
      setReviewReason();
    }
    if (e === "Approved") {
      setShowApprovalModal(true);
      setReviewStatus("Approved");
    } else {
      setReviewStatus("Rejected");
      setShowRejectionModal(true);
    }
  };

  const handleConclusionTrigger = async (decision) => {
    if (
      !melody ||
      !lyrics ||
      !project_score ||
      !market_potential ||
      !readiness ||
      !revv_factor
    ) {
      notification.error({
        message: "Please ensure that all fields are rated before submitting",
      });
      return;
    }
    if (decision === "Approved") {
      if (reviewer_genre.length < 2) {
        notification.error({
          message: "Please select at least 2 genres",
        });
        return;
      }
    }

    handleReason(decision);
  };

  const handleSubmitForm = async () => {
    setSubmitLoading(true);
    const payload = {
      review_status: review_status,
      reason_for_status: reason_for_status,
      scorecard: {
        melody: melody,
        lyrics: lyrics,
        project_score: project_score,
        market_potential: market_potential,
        readiness: readiness,
        revv_factor: revv_factor,
        reviewer_genre: reviewer_genre,
      },
    };
    try {
      const data = await updateReview(state._id, payload);
      const response = await getSingleProject(state);
      setData(response);
      setIsLoading(false);
      setSubmitLoading(false);
      setShowSubmitModal(false);
      setEdit(false);
      setSubmitted(true);
    } catch (error) {
      if (error.response) {
        notification.error({
          message: error.response.data.error,
        });

        setIsLoading(false);
        setSubmitLoading(false);
      } else {
        notification.error({
          message: "Network error",
        });
        setIsLoading(false);
        setSubmitLoading(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <ScorecardWrap>
        {isLoading ? (
          <Section height="70vh">
            <LoadingOutlined />
          </Section>
        ) : (
          <>
            <Section
              justifyContent="space-between"
              className="navigation__wrap"
            >
              <FlexibleDiv
                width="90%"
                className="prev__next__wrap"
                justifyContent="space-between"
                padding="0 40px"
              >
                {!!next?.prev && (
                  <Button
                    color="#FFF"
                    width="max-content"
                    padding="0 5px"
                    background="#679289"
                    hoverBg="#1D7874"
                    onClick={() =>
                      !!submitted ? handleNextScoreCard() : showPrevModal()
                    }
                  >
                    <Prev /> Previous
                  </Button>
                )}
                {!!next?.next && (
                  <Button
                    color="#FFF"
                    width="max-content"
                    padding="0 5px"
                    background="#679289"
                    hoverBg="#1D7874"
                    svgMargin="0 0 -4px 2px"
                    onClick={() => {
                      !!submitted ? handleNextScoreCard() : showNextModal();
                    }}
                  >
                    Next
                    <Next />
                  </Button>
                )}
              </FlexibleDiv>
              <FlexibleDiv
                width="max-content"
                className="close"
                onClick={() => handleCloseScorecard()}
              >
                <Close />
              </FlexibleDiv>
            </Section>

            <Section className="body__wrap">
              <Section className="body__wrap__sub">
                {/* project details */}
                <FlexibleDiv
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <FlexibleDiv className="projectDetails" width="60%">
                    <FlexibleDiv
                      className="projectDetails__top"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      flexWrap="nowrap"
                    >
                      <FlexibleDiv
                        width="90%"
                        justifyContent="flex-start"
                        flexWrap="nowrap"
                        alignItems="flex-start"
                      >
                        <FlexibleDiv
                          flexDir="column"
                          className="projecName__wrap"
                          width="max-content"
                        >
                          <img src={AudioMic} alt="" />
                          <span>{data?.project_name}</span>
                        </FlexibleDiv>
                        <FlexibleDiv
                          flexDir="column"
                          className="projectContent__wrap"
                        >
                          <FlexibleDiv justifyContent="space-between">
                            <p>Artist: {data?.project_owner?.moniker}</p>
                          </FlexibleDiv>
                          <FlexibleDiv justifyContent="space-between">
                            <p>
                              Full Name: {data?.project_owner?.first_name}{" "}
                              {data?.project_owner?.last_name}
                            </p>
                          </FlexibleDiv>
                          {/* <FlexibleDiv justifyContent="space-between">
                            <p>{data?.project_owner?.first_name}</p>
                            <p>{data?.project_owner?.last_name}</p>
                          </FlexibleDiv> */}
                          <FlexibleDiv justifyContent="space-between">
                            <p>Age: {data?.project_owner?.age}</p>
                            <p>
                              Country:{" "}
                              {data?.project_owner?.country_of_residence}
                            </p>
                          </FlexibleDiv>
                          <FlexibleDiv justifyContent="space-between">
                            <p>Gender: {data?.project_owner?.gender}</p>
                            <p>
                              Artist Type:{" "}
                              {data?.project_owner?.artist_status?.artist_type}
                            </p>
                          </FlexibleDiv>
                          <FlexibleDiv
                            justifyContent="flex-start"
                            flexWrap="nowrap"
                          >
                            <p>Signed to Label / Existing Deal?</p>
                            <FlexibleDiv width="50px" margin="0 0 0 15px">
                              <CustomCheck
                                noMargin
                                approved={
                                  data?.project_owner?.artist_status
                                    ?.signed_status === "Yes" && true
                                }
                                pending={
                                  data?.artist_status?.signed_status ===
                                    "Not Sure" && true
                                }
                                rejected={
                                  data?.artist_status?.signed_status === "No" &&
                                  true
                                }
                              />
                            </FlexibleDiv>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv>
                      <FlexibleDiv
                        className="socials__wrap"
                        width="70px"
                        justifyContent="flex-start"
                        flexWrap="nowrap"
                      >
                        {data?.project_owner?.social_media?.facebook ? (
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={data?.project_owner?.social_media?.facebook}
                          >
                            <Facebook />
                          </a>
                        ) : (
                          <span>
                            <Facebook className="no_link_svg" />
                          </span>
                        )}
                        {data?.project_owner?.social_media?.instagram ? (
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={data?.project_owner?.social_media?.instagram}
                          >
                            <Instagram />
                          </a>
                        ) : (
                          <span>
                            <Instagram className="no_link_svg" />
                          </span>
                        )}
                        {data?.project_owner?.social_media?.tik_tok ? (
                          <a
                            target={"_blank"}
                            rel="noreferrer"
                            href={data?.project_owner?.social_media?.tik_tok}
                          >
                            <Tiktok />
                          </a>
                        ) : (
                          <span>
                            <Tiktok className="no_link_svg" />
                          </span>
                        )}
                      </FlexibleDiv>
                    </FlexibleDiv>

                    <FlexibleDiv
                      className="projectDetails__bottom"
                      flexDir="column"
                      alignItems="flex-start"
                    >
                      <FlexibleDiv flexDir="column" alignItems="flex-start">
                        <h4>Crucials</h4>
                        <FlexibleDiv
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <span className="spaceText">Release Date</span>
                          <small className="spaceText">
                            {data?.project_status?.release_date}
                          </small>
                          <span className="spaceText">Project Type</span>
                          <small className="spaceText">
                            {data?.project_type}
                          </small>
                        </FlexibleDiv>
                        <FlexibleDiv
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <span className="spaceText">Project Phase</span>
                          <small className="spaceText">
                            {data?.project_status?.project_dev_phase}
                          </small>
                          <span className="spaceText">Distro Deal</span>
                          <FlexibleDiv
                            justifyContent="flex-start"
                            width="20%"
                            margin="0 0 20px 0"
                            className="spaceText"
                          >
                            <FlexibleDiv width="35px">
                              <CustomCheck
                                approved={
                                  data?.project_status?.distribution_deal ===
                                    "yes, I have one" && true
                                }
                                rejected={
                                  data?.project_status?.distribution_deal !==
                                    "yes, I have one" && true
                                }
                              />
                            </FlexibleDiv>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv>
                      <FlexibleDiv flexDir="column" alignItems="flex-start">
                        <h4>Other Info</h4>
                        <FlexibleDiv
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <span>Management</span>
                          <FlexibleDiv
                            justifyContent="flex-start"
                            width="20%"
                            margin="0 0 20px 0"
                          >
                            <FlexibleDiv width="35px">
                              <CustomCheck
                                approved={
                                  data?.artist_status?.artist_need?.includes(
                                    "Artist Management"
                                  ) && true
                                }
                                rejected={
                                  !data?.artist_status?.artist_need?.includes(
                                    "Artist Management"
                                  ) && true
                                }
                              />
                            </FlexibleDiv>
                          </FlexibleDiv>
                          <span>Spotify</span>
                          {!!data?.project_links?.spotify_link ? (
                            <a
                              href={data?.project_links?.spotify_link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Go to page
                            </a>
                          ) : (
                            <span style={{ textDecoration: "underline" }}>
                              N/A
                            </span>
                          )}
                          <FlexibleDiv width="20%" className="play">
                            <Play />
                          </FlexibleDiv>
                        </FlexibleDiv>
                        <FlexibleDiv
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <span>Marketing/PR</span>
                          <FlexibleDiv
                            justifyContent="flex-start"
                            width="20%"
                            margin="0 0 20px 0"
                          >
                            <FlexibleDiv width="35px">
                              <CustomCheck
                                approved={
                                  data?.artist_status?.artist_need?.includes(
                                    "Marketing & PR"
                                  ) && true
                                }
                                rejected={
                                  !data?.artist_status?.artist_need?.includes(
                                    "Marketing & PR"
                                  ) && true
                                }
                              />
                            </FlexibleDiv>
                          </FlexibleDiv>
                          <span>Youtube</span>
                          {!!data?.project_links?.youtube_link ? (
                            <a
                              href={data?.project_links?.youtube_link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Go to page
                            </a>
                          ) : (
                            <span style={{ textDecoration: "underline" }}>
                              N/A
                            </span>
                          )}

                          <FlexibleDiv width="20%" className="play">
                            <Play />
                          </FlexibleDiv>
                        </FlexibleDiv>
                        <FlexibleDiv
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <span>A/R</span>
                          <FlexibleDiv
                            justifyContent="flex-start"
                            width="20%"
                            margin="0 0 20px 0"
                          >
                            <FlexibleDiv width="35px">
                              <CustomCheck
                                approved={
                                  data?.artist_status?.artist_need?.includes(
                                    "A&R"
                                  ) && true
                                }
                                rejected={
                                  !data?.artist_status?.artist_need?.includes(
                                    "A&R"
                                  ) && true
                                }
                              />
                            </FlexibleDiv>
                          </FlexibleDiv>
                          <span>Other</span>
                          {!!data?.project_links?.other_link ? (
                            <a
                              href={data?.project_links?.other_link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Go to page
                            </a>
                          ) : (
                            <span style={{ textDecoration: "underline" }}>
                              N/A
                            </span>
                          )}
                          <FlexibleDiv width="20%" className="play">
                            <Play />
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv>
                    </FlexibleDiv>
                  </FlexibleDiv>

                  {/* review section  */}
                  <FlexibleDiv
                    className="reviewSection"
                    width="40%"
                    flexDir="column"
                    justifyContent="flex-start"
                  >
                    <FlexibleDiv
                      className="reviewSection__top"
                      alignItems="flex-start"
                      flexDir="column"
                    >
                      <FlexibleDiv
                        justifyContent="space-between"
                        className="projectStatus"
                      >
                        <h4>
                          Project Status: &nbsp;{" "}
                          <span>{data?.review[0]?.review_status}</span>
                        </h4>
                        <h4>Overall</h4>
                        <FlexibleDiv width="70px">
                          <RatingStars
                            rate={data?.review[0]?.scorecard?.overall}
                            small
                          />
                        </FlexibleDiv>
                      </FlexibleDiv>
                      <FlexibleDiv
                        justifyContent="flex-start"
                        className="musicLink"
                      >
                        <Music /> &nbsp; &nbsp;{" "}
                        <a
                          href={`${data?.project_links?.private_link}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {data?.project_links?.private_link}
                        </a>
                      </FlexibleDiv>
                      <FlexibleDiv
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <h4>Reviewer Notes &nbsp;</h4>{" "}
                        {user?.user_group !== "USER" ? (
                          <Edit
                            onClick={() => setEdit(!edit)}
                            className="edit"
                            id={!!edit && "edit_active"}
                          />
                        ) : (
                          <Edit
                            onClick={() =>
                              data?.review[0]?.reviewed_by === null &&
                              setEdit(!edit)
                            }
                            id={!!edit && "edit_active"}
                            className="edit"
                          />
                        )}
                      </FlexibleDiv>
                      <FlexibleDiv
                        className="ratingsSection"
                        justifyContent="space-between"
                      >
                        <FlexibleDiv width="40%" justifyContent="space-between">
                          <span>Melody</span>
                          <FlexibleDiv width="70px">
                            <RatingStars
                              rate={
                                melody || data?.review[0]?.scorecard?.melody
                              }
                              small
                              clickable={edit}
                              setScore={setMelody}
                            />
                          </FlexibleDiv>
                        </FlexibleDiv>
                        <FlexibleDiv width="45%" justifyContent="space-between">
                          <span>Market Potential</span>
                          <FlexibleDiv width="70px">
                            <RatingStars
                              rate={
                                market_potential ||
                                data?.review[0]?.scorecard?.market_potential
                              }
                              small
                              clickable={edit}
                              setScore={setMarketPotential}
                            />
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv>
                      <FlexibleDiv
                        className="ratingsSection"
                        justifyContent="space-between"
                      >
                        <FlexibleDiv width="40%" justifyContent="space-between">
                          <span>Lyrics</span>
                          <FlexibleDiv width="70px">
                            <RatingStars
                              rate={
                                lyrics || data?.review[0]?.scorecard?.lyrics
                              }
                              small
                              clickable={edit}
                              setScore={setLyrics}
                            />
                          </FlexibleDiv>
                        </FlexibleDiv>
                        <FlexibleDiv width="45%" justifyContent="space-between">
                          <span>Readiness</span>
                          <FlexibleDiv width="70px">
                            <RatingStars
                              rate={
                                readiness ||
                                data?.review[0]?.scorecard?.readiness
                              }
                              small
                              clickable={edit}
                              setScore={setReadiness}
                            />
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv>{" "}
                      <FlexibleDiv
                        className="ratingsSection"
                        justifyContent="space-between"
                      >
                        <FlexibleDiv width="40%" justifyContent="space-between">
                          <span>Project</span>
                          <FlexibleDiv width="70px">
                            <RatingStars
                              rate={
                                project_score ||
                                data?.review[0]?.scorecard?.project_score
                              }
                              small
                              clickable={edit}
                              setScore={setProjecScore}
                            />
                          </FlexibleDiv>
                        </FlexibleDiv>
                        <FlexibleDiv width="45%" justifyContent="space-between">
                          <span>Revv Factor</span>
                          <FlexibleDiv width="70px">
                            <RatingStars
                              rate={
                                revv_factor ||
                                data?.review[0]?.scorecard?.revv_factor
                              }
                              small
                              clickable={edit}
                              setScore={setRevFactor}
                            />
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv>
                      <FlexibleDiv
                        justifyContent="flex-start"
                        margin="20px 0 0 0"
                      >
                        <h4>PopRev Categories &nbsp;</h4>{" "}
                        <Plus
                          onClick={() => !!edit && setShowGenreModal(true)}
                          className={!!edit && "plus"}
                        />
                      </FlexibleDiv>
                      <FlexibleDiv
                        className="categories"
                        justifyContent="flex-start"
                      >
                        {(
                          reviewer_genre ||
                          data?.review[0]?.scorecard?.reviewer_genre
                        )?.map((item, idx) => (
                          <span>{item}</span>
                        ))}
                      </FlexibleDiv>
                      <FlexibleDiv justifyContent="flex-start" margin="10px 0">
                        <h4>Decision</h4>
                      </FlexibleDiv>
                      <FlexibleDiv width="320px" justifyContent="space-between">
                        <Button
                          width="150px"
                          color="#fff"
                          background="#1D7874"
                          hoverBg="#114543"
                          onClick={() => handleConclusionTrigger("Approved")}
                          disabled={edit === false && true}
                        >
                          Approve
                        </Button>
                        <Button
                          width="150px"
                          color="#fff"
                          background="#926767"
                          hoverBg="#5d3636"
                          onClick={() => handleConclusionTrigger("Rejected")}
                          disabled={edit === false && true}
                        >
                          Reject
                        </Button>
                      </FlexibleDiv>
                    </FlexibleDiv>
                    <FlexibleDiv
                      className="nominatedGenres"
                      flexDir="column"
                      alignItems="flex-start"
                    >
                      <span>Artist Nominated Genres</span>
                      <FlexibleDiv
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        {data?.project_genre?.map((item, idx) => (
                          <small>{item}</small>
                        ))}
                      </FlexibleDiv>
                    </FlexibleDiv>
                  </FlexibleDiv>
                </FlexibleDiv>

                {/* Footer */}
                <FlexibleDiv
                  alignItems="flex-start"
                  className="projectDetails__footer"
                >
                  <FlexibleDiv
                    justifyContent="flex-start"
                    width="60%"
                    className="footer__left"
                  >
                    <h4>Why PopRev</h4>
                    <span>{data?.extra_info?.why_pop_rev}</span>
                    <h4>Found us</h4>
                    <span>{data?.extra_info?.how_pop_rev}</span>
                  </FlexibleDiv>
                  <FlexibleDiv
                    justifyContent="flex-start"
                    width="40%"
                    className="footer__right"
                  >
                    <h4>Reviewd By: </h4> &nbsp; &nbsp;
                    <span>{data?.review[0]?.reviewed_by?.username}</span>
                  </FlexibleDiv>
                </FlexibleDiv>
              </Section>
            </Section>
          </>
        )}
      </ScorecardWrap>

      {/* MODALS */}
      <CloseScoreCard
        setShowCloseCardModal={setShowCloseCardModal}
        showCloseCardModal={showCloseCardModal}
        changeCard={changeCard}
        handleNextScoreCard={handleNextScoreCard}
        handlePrevScoreCard={handlePrevScoreCard}
        setchangeCard={setchangeCard}
      />
      <GenreModal
        showGenreModal={showGenreModal}
        setShowGenreModal={setShowGenreModal}
        setReviewerGenre={setReviewerGenre}
        reviewer_genre={reviewer_genre}
      />
      <ApprovalReason
        reason_for_status={reason_for_status}
        setReviewReason={setReviewReason}
        showApprovalModal={showApprovalModal}
        setShowApprovalModal={setShowApprovalModal}
        setReviewStatus={setReviewStatus}
        setShowSubmitModal={setShowSubmitModal}
      />
      <RejectionReason
        reason_for_status={reason_for_status}
        setReviewReason={setReviewReason}
        showRejectionModal={showRejectionModal}
        setShowRejectionModal={setShowRejectionModal}
        setReviewStatus={setReviewStatus}
        setShowSubmitModal={setShowSubmitModal}
      />
      <SubmitModal
        setShowSubmitModal={setShowSubmitModal}
        showSubmitModal={showSubmitModal}
        handleSubmitForm={handleSubmitForm}
        submitLoading={submitLoading}
      />
    </DashboardLayout>
  );
};

export default Scorecard;
