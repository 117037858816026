import React from "react";
import { Layout } from "antd";
import { FlexibleDiv } from "../../Box/styles";
import { DashboardSideBarStyles } from "./sidebar.styles";
import { useNavigate, useLocation } from "react-router-dom";
import { SideBarItems } from "../../../utils/dataHelpers/sideBarDate";
import Logo from "../../../assets/Img/LoginImages/logo.svg";
import { RiLogoutCircleRLine as Logout } from "react-icons/ri";

const DashboardSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { Sider } = Layout;
  const activePath = location.pathname;

  const logout = () => {
    localStorage.removeItem("admin_token");
    navigate("/login");
  };

  return (
    <DashboardSideBarStyles>
      <Sider>
        <FlexibleDiv height="100%">
          <FlexibleDiv height="95%">
            <FlexibleDiv className="logoWrap" justifyContent="flex-start">
              <img src={Logo} alt="" />
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent="space-between"
              height="90%"
              flexDir="column"
              className="sideBarContent__wrap"
            >
              <FlexibleDiv flexDir="column" className="sideBarContent__wrapsub">
                {SideBarItems.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      item.class ? "sidebar__item subItems" : "sidebar__item"
                    }
                    key={idx}
                    justifyContent="flex-start"
                    onClick={() => {
                      navigate(item.route);
                    }}
                    id={activePath === item.route && "activePath"}
                  >
                    {item.icon} <span>{item.content}</span>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>

              <FlexibleDiv
                className="sidebar__item"
                justifyContent="flex-start"
                onClick={logout}
              >
                <Logout /> <span>Log Out</span>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </Sider>
    </DashboardSideBarStyles>
  );
};

export default DashboardSideBar;
