import React from "react";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { FlexibleDiv } from "../../../../components/Box/styles";
import Button from "../../../../components/Button";
import { IoMdArrowBack as BackIcon } from "react-icons/io";
import { LoadingOutlined } from "@ant-design/icons";

const SubmitModal = ({
  setShowSubmitModal,
  showSubmitModal,
  handleSubmitForm,
  submitLoading,
}) => {
  const closeModal = () => {
    setShowSubmitModal(false);
  };
  return (
    <ModalWrapper
      title=""
      visible={showSubmitModal}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      centered={true}
      closable={false}
    >
      <FlexibleDiv className="header">
        <FlexibleDiv className="back" onClick={closeModal}>
          <BackIcon />
        </FlexibleDiv>
        <h3>THINK CAREFULLY! 😂</h3>
      </FlexibleDiv>
      <FlexibleDiv flexDir="column">
        <p className="bodyText">
          Once confirmed this action cannot be undone.{" "}
        </p>
        <Button onClick={() => handleSubmitForm()}>
          {submitLoading && <LoadingOutlined />}Confirm{" "}
        </Button>
      </FlexibleDiv>
    </ModalWrapper>
  );
};

export default SubmitModal;
