import React from "react";
import { FlexibleDiv } from "../Box/styles";
import { CheckWrap } from "./tick.styles";
import {
  AiFillCheckCircle as Check,
  AiFillCloseCircle as Cancel,
} from "react-icons/ai";
import { BsQuestionCircleFill as Question } from "react-icons/bs";

const CustomCheck = ({ pending, approved, rejected, noMargin }) => {
  return (
    <CheckWrap noMargin={noMargin}>
      <FlexibleDiv justifyContent="space-between" flexWrap="nowrap">
        {approved && <Check className="green" />}
        {pending && <Question className="yellow" />}
        {rejected && <Cancel className="red" />}
      </FlexibleDiv>
    </CheckWrap>
  );
};
export default CustomCheck;
