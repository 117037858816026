import styled from "styled-components";

export const DashboardSideBarStyles = styled.div`
  width: max-content;
  height: 100vh;
  min-height: 100vh;
  position: fixed;
  left: 0;
  .ant-layout-sider {
    background: #679289;
    height: 100%;
    .ant-layout-sider-children {
      height: 100%;
      .logoWrap {
        padding: 0 0 0 12%;
        img {
          width: 80px;
        }
      }

      .sidebar__item {
        padding: 7px 12%;
        margin: 12px 0 0 0;
        cursor: pointer;
        transition: all 0.3s ease;

        :hover {
          background: #ffffff33;
        }
        svg {
          margin-right: 7px;
          font-size: 17px;
          color: #eef5db;
        }
        span {
          color: #fff;
        }
      }
      .subItems {
        padding: 5px 12% 5px 25%;
        margin: 4px 0;

        margin: 0;
        svg {
          font-size: 15px;
        }
        span {
          font-size: 12px;
        }
      }
      #activePath {
        background: #1d7874;
      }
    }
  }
`;
