import { instance } from "./axios";

export const getSearchProjects = async (payload) => {
  const { data } = await instance.get(
    `search/project?search=${payload.search}`
  );
  return data;
};
export const getSearchArtists = async (payload) => {
  const { data } = await instance.get(`search/artist?search=${payload.search}`);
  return data;
};
export const getSearchRejected = async (payload) => {
  const { data } = await instance.get(
    `search/rejected/project?search=${payload.search}`
  );
  return data;
};
export const getSearchApproved = async (payload) => {
  const { data } = await instance.get(
    `search/approved/project?search=${payload.search}`
  );
  return data;
};
export const getSearchPending = async (payload) => {
  const { data } = await instance.get(
    `search/pending/project?search=${payload.search}`
  );
  return data;
};
