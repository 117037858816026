import React from "react";
import { FaDotCircle as Project } from "react-icons/fa";
import { AiTwotoneAudio as Artists } from "react-icons/ai";
import { MdThumbUp as Accepted } from "react-icons/md";
import { MdThumbDownAlt as Rejected } from "react-icons/md";
import { MdRateReview as Review } from "react-icons/md";

export const SideBarItems = [
  {
    content: "Artist",
    route: "/",
    icon: <Artists />,
  },
  {
    content: "Project",
    route: "/projects",
    icon: <Project />,
  },
  {
    content: "Accepted",
    route: "/projects/accepted",
    class: "sub",
    icon: <Accepted />,
  },
  {
    content: "Rejected",
    class: "sub",
    route: "/projects/rejected",
    icon: <Rejected />,
  },
  {
    content: "Review",
    class: "sub",
    route: "/projects/review",
    icon: <Review />,
  },
];
