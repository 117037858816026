import styled from "styled-components";

export const CheckWrap = styled.div`
  width: 100%;

  svg {
    font-size: 14px;
    margin: ${({ noMargin }) => (noMargin ? "0" : "5px 0 0 0")};
  }
  .red {
    color: #e0acac;
  }
  .green {
    color: #ace0b6;
  }
  .yellow {
    color: #e0d0ac;
  }
`;
