import { instance } from "./axios";

export const getDateProjects = async (payload) => {
  const { data } = await instance.get(
    `/project/filter/date?from=${payload.dateFrom}&to=${payload.dateTo}`
  );
  return data;
};
export const getDateArtists = async (payload) => {
  const { data } = await instance.get(
    `/artist/filter/date?from=${payload.dateFrom}&to=${payload.dateTo}`
  );
  return data;
};
export const getDateRejected = async (payload) => {
  const { data } = await instance.get(
    `/project/rejected/filter/date?from=${payload.dateFrom}&to=${payload.dateTo}`
  );
  return data;
};
export const getDateApproved = async (payload) => {
  const { data } = await instance.get(
    `/project/approved/filter/date?from=${payload.dateFrom}&to=${payload.dateTo}`
  );
  return data;
};
export const getDatePending = async (payload) => {
  const { data } = await instance.get(
    `/project/pending/filter/date?from=${payload.dateFrom}&to=${payload.dateTo}`
  );
  return data;
};
