import React from "react";
import { FlexibleDiv } from "../Box/styles";
import { SumTotalWrap } from "./sumTotal.styles";
import { CgNotes as Notes } from "react-icons/cg";
import { useQuery } from "react-query";
import {
  getAllPendingProjects,
  getAllRejectedProjects,
  getAllApprovedProjects,
  getAllProjects,
} from "../../network/projects";
import { getAllArtists } from "../../network/artist";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const SumTotal = () => {
  const location = useLocation();
  const { isLoading: pendingLoading, data: pending } = useQuery(
    "pending",
    async () => await getAllPendingProjects()
  );
  const { isLoading: artistLoading, data: artists } = useQuery(
    "artists",
    async () => await getAllArtists()
  );
  const { isLoading: aprovedLoading, data: approved } = useQuery(
    "approved",
    async () => await getAllApprovedProjects()
  );
  const { isLoading: rejectedLoading, data: rejected } = useQuery(
    "rejected",
    async () => await getAllRejectedProjects()
  );
  const { isLoading: projectLoading, data: projects } = useQuery(
    "projects",
    async () => await getAllProjects()
  );

  return (
    <SumTotalWrap>
      <FlexibleDiv justifyContent="flex-start">
        <FlexibleDiv
          className="card"
          flexWrap="nowrap"
          justifyContent="flex-start"
        >
          <FlexibleDiv width="max-content" className="cardIcon artist">
            <Notes />
          </FlexibleDiv>
          <FlexibleDiv
            width="max-content"
            flexDir="column"
            alignItems="flex-start"
          >
            <small>Artist Applications</small>

            {location.pathname === "/" &&
              (artistLoading ? <LoadingOutlined /> : <p>{artists?.length}</p>)}
            {location.pathname.includes("/projects") &&
              (projectLoading ? (
                <LoadingOutlined />
              ) : (
                <p>{projects?.length}</p>
              ))}
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv
          className="card"
          flexWrap="nowrap"
          justifyContent="flex-start"
        >
          <FlexibleDiv width="max-content" className="cardIcon pending">
            <Notes />
          </FlexibleDiv>
          <FlexibleDiv
            width="max-content"
            flexDir="column"
            alignItems="flex-start"
          >
            <small>Awaiting Feedback</small>
            {pendingLoading ? <LoadingOutlined /> : <p>{pending?.length}</p>}
          </FlexibleDiv>
        </FlexibleDiv>{" "}
        <FlexibleDiv
          className="card"
          flexWrap="nowrap"
          justifyContent="flex-start"
        >
          <FlexibleDiv width="max-content" className="cardIcon accepted">
            <Notes />
          </FlexibleDiv>
          <FlexibleDiv
            width="max-content"
            flexDir="column"
            alignItems="flex-start"
          >
            <small>Approved</small>
            {aprovedLoading ? <LoadingOutlined /> : <p>{approved?.length}</p>}
          </FlexibleDiv>
        </FlexibleDiv>{" "}
        <FlexibleDiv
          className="card"
          flexWrap="nowrap"
          justifyContent="flex-start"
        >
          <FlexibleDiv width="max-content" className="cardIcon rejected">
            <Notes />
          </FlexibleDiv>
          <FlexibleDiv
            width="max-content"
            flexDir="column"
            alignItems="flex-start"
          >
            <small>Rejected</small>
            {rejectedLoading ? <LoadingOutlined /> : <p>{rejected?.length}</p>}
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </SumTotalWrap>
  );
};

export default SumTotal;
