import React, { useContext, useEffect } from "react";
import { Layout, Form } from "antd";
import { FlexibleDiv } from "../../Box/styles";
import { HeaderStyles } from "./header.styles";
import TextField from "../../TextField";
import Button from "../../Button";
import { FaSearch as Search } from "react-icons/fa";
import { MainContext } from "../../../context/MainContext";
import {
  getSearchProjects,
  getSearchArtists,
  getSearchRejected,
  getSearchApproved,
  getSearchPending,
} from "../../../network/search";
import { getAllArtists } from "../../../network/artist";
import {
  getAllProjects,
  getAllApprovedProjects,
  getAllPendingProjects,
  getAllRejectedProjects,
} from "../../../network/projects";
import { CURRENT_USER } from "../../../context/types";
import {
  ARTIST_API,
  PROJECT_API,
  PENDING_API,
  REJECTED_API,
  APRROVED_API,
} from "../../../context/types";
import { useLocation, useNavigate } from "react-router-dom";
import { getCuurentUser } from "../../../network/user";
import { useIdleTimer } from "react-idle-timer";

const DashboardHeader = () => {
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { Header } = Layout;

  function handleOnIdle() {
    localStorage.removeItem("admin_token");
    navigate("/login");
  }

  useIdleTimer({
    timeout: 40 * 12500,
    onIdle: handleOnIdle,
    debounce: 200,
  });

  useEffect(() => {
    const getUser = async () => {
      const data = await getCuurentUser();
      dispatch({ type: CURRENT_USER, payload: data });
    };
    getUser();
  }, [dispatch]);

  const handleSubmit = async (values) => {
    if (!values) return;
    const payload = {
      ...values,
    };
    if (location.pathname === "/") {
      await dispatch({
        type: ARTIST_API,
        payload: { api: getSearchArtists, payload: payload },
      });
    }
    if (location.pathname === "/projects") {
      await dispatch({
        type: PROJECT_API,
        payload: { api: getSearchProjects, payload: payload },
      });
    }
    if (location.pathname === "/accepted") {
      await dispatch({
        type: APRROVED_API,
        payload: { api: getSearchApproved, payload: payload },
      });
    }
    if (location.pathname === "/rejected") {
      await dispatch({
        type: REJECTED_API,
        payload: { api: getSearchRejected, payload: payload },
      });
    }
    if (location.pathname === "/review") {
      await dispatch({
        type: PENDING_API,
        payload: { api: getSearchPending, payload: payload },
      });
    }
  };

  const handleReset = async ({ target }) => {
    const { value } = target;
    if (value === "") {
      if (location.pathname === "/") {
        await dispatch({
          type: ARTIST_API,
          payload: { api: getAllArtists, payload: {} },
        });
      }
      if (location.pathname === "/projects") {
        await dispatch({
          type: PROJECT_API,
          payload: { api: getAllProjects, payload: {} },
        });
      }
      if (location.pathname === "/accepted") {
        await dispatch({
          type: APRROVED_API,
          payload: { api: getAllApprovedProjects, payload: {} },
        });
      }
      if (location.pathname === "/rejected") {
        await dispatch({
          type: REJECTED_API,
          payload: { api: getAllRejectedProjects, payload: {} },
        });
      }
      if (location.pathname === "/review") {
        await dispatch({
          type: PENDING_API,
          payload: { api: getAllPendingProjects, payload: {} },
        });
      }
    }
  };

  return (
    <HeaderStyles>
      <Header>
        <FlexibleDiv
          height="100%"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Form onFinish={handleSubmit}>
            <FlexibleDiv flexWrap="nowrap">
              <TextField
                height="40px"
                margin="0"
                name="search"
                onChange={handleReset}
              />
              <Button
                width="100px"
                background="#071E22"
                color="#EEF5DB"
                hoverColor="071E22"
                border="none"
                hoverBg="#074854"
                type="submit"
              >
                <Search />
              </Button>
            </FlexibleDiv>
          </Form>
          <FlexibleDiv
            className="userDetails__wrap"
            width="100px"
            justifyContent="flex-end"
          >
            {!!user && <h3>Hi {user?.username}</h3>}
          </FlexibleDiv>
        </FlexibleDiv>
      </Header>
    </HeaderStyles>
  );
};

export default DashboardHeader;
