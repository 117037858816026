import React, { useState } from "react";
import { FormItem, TextFieldWrapper } from "./textfield.styles";
import {
  AiFillEye as EyeVisible,
  AiFillEyeInvisible as EyeInvisible,
} from "react-icons/ai";

const TextField = ({ name, placeholder, height, margin, onChange, type }) => {
  const [passwordType, setPasswordType] = useState("password");

  return (
    <TextFieldWrapper margin={margin}>
      <FormItem
        name={name}
        height={height}
        rules={[
          {
            required: true,
            message: "Please fill the input field",
          },
        ]}
      >
        <input
          placeholder={placeholder}
          onChange={onChange}
          type={type === "password" ? passwordType : "text"}
        />
      </FormItem>
      {type === "password" && (
        <div className="password__eye">
          {passwordType === "password" ? (
            <EyeVisible onClick={() => setPasswordType("text")} />
          ) : (
            <EyeInvisible onClick={() => setPasswordType("password")} />
          )}
        </div>
      )}
    </TextFieldWrapper>
  );
};

export default TextField;
