import styled from "styled-components";
import { Form } from "antd";

export const FormItem = styled(Form.Item)`
  width: ${({ width }) => width || "100%"};
  margin: 0;
  height: ${({ height }) => height || "50px"};
  /* padding: ${({ padding, borderwidth }) =>
    borderwidth ? "0" : padding || "0 10px"}; */
  background: ${({ background }) => background || "transparent"};
  border: ${({ border }) => border || "1px solid #EEF5DB"};
  border-width: ${({ borderwidth }) => borderwidth || ".5px"};
  border-radius: ${({ radius }) => radius || "none"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;

  :hover,
  :active,
  :focus,
  :visited {
    border-color: #679289;
    border-width: ${({ borderwidth }) => borderwidth || ".5px"};
  }

  input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 0 30px 0 10px;

    ::placeholder {
      color: #679289;
      font-size: 16px;
    }
  }

  svg {
    color: #679289;
    font-size: 18px;
  }
  .ant-form-item-control-input-content {
    height: ${({ height }) => height || "50px"};
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
`;
export const TextFieldWrapper = styled.div`
  width: ${({ width }) => width || "100%"};
  position: relative;
  height: max-content;
  margin: ${({ margin }) => margin || "0 0 20px 0"};

  .password__eye {
    position: absolute;
    color: #679289;
    font-size: 18px;
    right: 10px;
    cursor: pointer;
    bottom: 10px;
  }
`;
