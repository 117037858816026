import { createContext, useContext, useReducer } from "react";
import { Reducer } from "./reducer";
import { getAllArtists } from "../network/artist";
import {
  getAllProjects,
  getAllPendingProjects,
  getAllApprovedProjects,
  getAllRejectedProjects,
} from "../network/projects";

export const MainContext = createContext({
  user: {},
  artistApi: { api: getAllArtists, payload: {} },
  projectApi: { api: getAllProjects, payload: {} },
  pendingApi: { api: getAllPendingProjects, payload: {} },
  approvedApi: { api: getAllApprovedProjects, payload: {} },
  rejectedApi: { api: getAllRejectedProjects, payload: {} },
});

export const MainProvider = ({ children }) => {
  const initialState = useContext(MainContext);
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  );
};
