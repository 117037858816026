import { instance } from "./axios";

export const getAllPendingProjects = async () => {
  const { data } = await instance.get("project/pending/all");
  return data;
};
export const getAllProjects = async () => {
  const { data } = await instance.get("project/all");
  return data;
};
export const getAllApprovedProjects = async () => {
  const { data } = await instance.get("project/approved/all");
  return data;
};
export const getAllRejectedProjects = async () => {
  const { data } = await instance.get("project/rejected/all");
  return data;
};
export const getSingleProject = async (payload) => {
  const { data } = await instance.get(`/project/review/${payload._id}`);
  return data;
};

export const updateReview = async (id, payload) => {
  const { data } = await instance.put(`/review/submit/${id}`, payload);
  return data;
};
