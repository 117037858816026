import styled from "styled-components";
import { Modal } from "antd";

export const ModalWrapper = styled(Modal)`
  .radioItems {
    padding: 7px;
    border: 0.5px solid #eef5db99;
    margin-bottom: 15px;
    cursor: pointer;
    color: #fff;
    transition: all 0.3s ease;
    font-size: 12px;
    svg {
      color: #707070;
      font-size: 14px;
    }
  }
  .active {
    background: #ffffff22;
    svg {
      color: #fff;
    }
  }

  p {
    color: #fff;
    margin: 0;
  }
  .header {
    margin-bottom: 20px;
    position: relative;
    .back {
      position: absolute;
      cursor: pointer;
      tansition: all 0.3s ease;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      background: #eef5db;
      :hover {
        width: 20px;
        height: 20px;
      }
    }
    h3 {
      color: #fff;
      font-size: 14px;
    }
  }
  .genreItems {
    padding: 5px 0;
    cursor: pointer;
    svg {
      margin-right: 5px;
      color: #707070;
    }
    span {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }
  }
  .activeGenre {
    svg {
      color: #fff;
      font-size: 16px;
    }
  }
  .ant-modal-content {
    background: #071e22;
    max-width: ${({ maxWidth }) => maxWidth || ""};
  }
  button {
    background: #679289;
    border: none;
    border-radius: 0;
    margin-top: 40px;
    color: #fff;
    :hover,
    :focus,
    :active {
      background: #1d7874;
      border: none;
    }
  }
`;
