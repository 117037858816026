import React, { useContext, useState, useEffect } from "react";
import { DateFilterWrap } from "./datePicker.styles";
import { DatePicker } from "antd";
import { FlexibleDiv } from "../Box/styles";
import { BsArrowDownCircleFill as Arrow } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import {
  getDateProjects,
  getDateArtists,
  getDateRejected,
  getDateApproved,
  getDatePending,
} from "../../network/dateFilter";
import moment from "moment";
import { MainContext } from "../../context/MainContext";
import {
  ARTIST_API,
  PROJECT_API,
  PENDING_API,
  REJECTED_API,
  APRROVED_API,
} from "../../context/types";
import Button from "../Button";
import { getAllArtists } from "../../network/artist";
import {
  getAllProjects,
  getAllApprovedProjects,
  getAllPendingProjects,
  getAllRejectedProjects,
} from "../../network/projects";

const DateFilter = () => {
  const location = useLocation();
  const [date, setDate] = useState({});
  const {
    dispatch,
    state: { pendingApi, approvedApi, artistApi, rejectedApi, projectApi },
  } = useContext(MainContext);

  const handleDateFrom = (newDate) => {
    setDate({ ...date, dateFrom: moment(newDate).format("YYYY-MM-DD") });
  };
  const handleDateTo = async (newDate) => {
    setDate({ ...date, dateTo: moment(newDate).format("YYYY-MM-DD") });

    const payload = {
      dateFrom: date?.dateFrom,
      dateTo: moment(newDate).format("YYYY-MM-DD"),
    };

    if (location.pathname === "/") {
      await dispatch({
        type: ARTIST_API,
        payload: { api: getDateArtists, payload: payload },
      });
    }
    if (location.pathname === "/projects") {
      await dispatch({
        type: PROJECT_API,
        payload: { api: getDateProjects, payload: payload },
      });
    }
    if (location.pathname === "/accepted") {
      await dispatch({
        type: APRROVED_API,
        payload: { api: getDateApproved, payload: payload },
      });
    }
    if (location.pathname === "/rejected") {
      await dispatch({
        type: REJECTED_API,
        payload: { api: getDateRejected, payload: payload },
      });
    }
    if (location.pathname === "/review") {
      await dispatch({
        type: PENDING_API,
        payload: { api: getDatePending, payload: payload },
      });
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setDate({
        dateFrom: artistApi?.payload?.dateFrom,
        dateTo: artistApi?.payload?.dateTo,
      });
    }
    if (location.pathname === "/projects") {
      setDate({
        dateFrom: projectApi?.payload?.dateFrom,
        dateTo: projectApi?.payload?.dateTo,
      });
    }
    if (location.pathname === "/accepted") {
      setDate({
        dateFrom: approvedApi?.payload?.dateFrom,
        dateTo: approvedApi?.payload?.dateTo,
      });
    }
    if (location.pathname === "/rejected") {
      setDate({
        dateFrom: rejectedApi?.payload?.dateFrom,
        dateTo: rejectedApi?.payload?.dateTo,
      });
    }
    if (location.pathname === "/review") {
      setDate({
        dateFrom: pendingApi?.payload?.dateFrom,
        dateTo: pendingApi?.payload?.dateTo,
      });
    }
  }, [
    pendingApi,
    projectApi,
    approvedApi,
    rejectedApi,
    artistApi,
    location.pathname,
  ]);

  const handleReset = async () => {
    if (location.pathname === "/") {
      await dispatch({
        type: ARTIST_API,
        payload: { api: getAllArtists, payload: {} },
      });
    }
    if (location.pathname === "/projects") {
      await dispatch({
        type: PROJECT_API,
        payload: { api: getAllProjects, payload: {} },
      });
    }
    if (location.pathname === "/accepted") {
      await dispatch({
        type: APRROVED_API,
        payload: { api: getAllApprovedProjects, payload: {} },
      });
    }
    if (location.pathname === "/rejected") {
      await dispatch({
        type: REJECTED_API,
        payload: { api: getAllRejectedProjects, payload: {} },
      });
    }
    if (location.pathname === "/review") {
      await dispatch({
        type: PENDING_API,
        payload: { api: getAllPendingProjects, payload: {} },
      });
    }
  };

  return (
    <DateFilterWrap>
      <FlexibleDiv justifyContent="flex-start">
        <FlexibleDiv
          flexDir="column"
          alignItems="flex-start"
          width="flex-start"
        >
          <small>Application Date</small>
          <DatePicker
            placeholder="Date From"
            suffixIcon={<Arrow />}
            onChange={(e) => {
              handleDateFrom(e);
            }}
            value={!!date?.dateFrom && moment(date?.dateFrom, "YYYY-MM-DD")}
          />
        </FlexibleDiv>
        <FlexibleDiv
          flexDir="column"
          alignItems="flex-start"
          width="flex-start"
          className={!date?.dateFrom && "disabled"}
        >
          <small>Application Date</small>
          <DatePicker
            placeholder="Date To"
            suffixIcon={<Arrow />}
            onChange={(e) => {
              handleDateTo(e);
            }}
            value={!!date?.dateTo && moment(date?.dateTo, "YYYY-MM-DD")}
          />
        </FlexibleDiv>
        {!!date?.dateTo && (
          <Button
            width="120px"
            height="30px"
            margin="15px 0 0 0"
            onClick={handleReset}
          >
            Reset
          </Button>
        )}
      </FlexibleDiv>
    </DateFilterWrap>
  );
};

export default DateFilter;
