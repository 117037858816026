import styled from "styled-components";

export const ProjectWrap = styled.div`
  padding: 0;
  background: transparent;

  .projectContent__wrap {
    padding: 40px 20px;

    h3 {
      margin: 0;
      font-size: 22px;
      line-height: 0;
    }
  }
`;
