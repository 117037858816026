import styled from "styled-components";

export const LoginStylesWrap = styled.div`
  width: 100%;
  height: 100vh;

  .login__leftSection {
    background-color: #679289;
    img {
      width: 90%;
    }
  }
  .login__rightSection {
    background-color: #071e22;
    padding: 20px 40px 0 7%;

    @media (max-width: 1084px) {
      padding-left: 3%;
    }
    img {
      width: 100px;
    }
    .formWrap {
      max-width: 430px;
      h2,
      p {
        color: #ffffff;
      }
      h2 {
        font-size: 80px;
        line-height: 90px;
        margin-top: 0;
        @media (max-height: 600px) {
          font-size: 60px;
        }
      }
      p {
        font-size: 16px;
        margin-bottom: 50px;
      }
      form {
        width: 100%;
        button {
          margin-top: 50px;
        }
      }
    }
  }
`;
