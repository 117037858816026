import styled from "styled-components";

export const TableStyles = styled.section`
  width: 100%;
  height: max-content;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
  margin-top: 20px;

  .ant-table-wrapper {
    border-radius: 0;
  }
  .ant-table {
    background: transparent;
  }
  .ant-table-thead > tr > th {
    background: #1d7874 !important;
    border: 0.1px solid #bdbdbd;
    color: #4f4f4f;
    font-weight: bold;
    padding: 16px 8px;

    h3 {
      white-space: nowrap;
      color: #fff;
      margin: 0;
      font-size: 16px !important;
    }
    svg {
      color: #fff;
    }
    ::before {
      display: none;
    }
  }
  .ant-table-tbody > tr > td {
    background: transparent !important;
    border: 0.1px solid #bdbdbd;
    padding: 18px 10px 10px 10px;
    color: #1d7874;
    text-align: center;
    max-width: 200px;
    min-width: 100px;
  }

  p,
  span {
    margin: 0;
  }

  .content_id {
    text-decoration: underline;
    cursor: pointer;
  }

  .projectType {
    text-align: center;
    padding: 5px 7px;
    color: #000;
  }
  .ep__type {
    background: #cc00b1;
  }
  .lp__type {
    background: #e07be0;
  }
  .reviewer {
    padding: 5px 12px;
    color: #000;
    background: #98a8ce;
    width: max-content;
  }

  .outcome {
    padding: 5px 12px;
    color: #000;
  }
  .approved {
    background: #21ae00;
  }

  .rejected {
    background: #d8211e;
  }
  .notRated {
    text-align: center !important;
  }
  .profile,
  .gender {
    padding: 5px 12px;
    color: #000;
    text-align: center;
  }
  .manager {
    background: #b999ff;
  }
  .label {
    background: #ae5bff;
  }
  .artist {
    background: #7e47ff;
  }
  .pending {
    background: #fde758;
  }
  .female {
    background: #cc00b1;
  }
  .male {
    background: #e07be0;
  }
  .non {
    background: #b999ff;
  }
  .alignText {
    text-align: center;
  }
  .ant-table-filter-column {
    h3 {
      padding-top: 5px;
      margin-right: 10px;
    }
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    .ant-table-filter-column {
      h3 {
        padding-top: 0;
        margin-right: 10px;
      }
    }
  }
`;
