export const genderFilter = ["Male", "Female", "Non-Binary"];
export const genreFilter = [
  "RAP",
  "RNB",
  "AFROBEATS",
  "AFROHOUSE",
  "DANCEHALL",
  "ROCK/PUNK",
  "ALTE VIBES",
  "FUSION",
  "HIGHLIFE",
  "JUJU",
  "FUJI",
  "ELECTRONIC",
];

export const approvalReason = [
  "Project is Perfect",
  "The project needs some Tweaks",
  "I love the project - release concerns",
];
export const rejectionReason = [
  "Project is not ready (too much work to fix)",
  "Artist has potential but not right now",
  "Not quite what we’re looking for",
  "It doesn't align with our release plans for the year",
];
