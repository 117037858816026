import React, { useState, useEffect, useContext, useCallback } from "react";
import { TableStyles } from "./table.style";
import { Table } from "antd";
import { OverFlowScrollBar } from "../OverflowScroll/styles";
import { MainContext } from "../../context/MainContext";
import { PAGE_DATA } from "../../context/types";

const CustomTable = ({
  columns,
  func,
  maxWidth,
  defaultPageSize,
  setSelectedData,
  setTableChange,
}) => {
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({
    hideOnSinglePage: true,
    current: 1,
    pageSize: defaultPageSize || 20,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(MainContext);

  const setCurrentPageData = useCallback(
    async (data, page) => {
      const newTable = [...data];
      const { current, pageSize } = page;
      const start = (current - 1) * pageSize;
      const end = current * pageSize;
      await dispatch({ type: PAGE_DATA, payload: newTable.slice(start, end) });
    },
    [dispatch]
  );

  useEffect(() => {
    if (func) {
      async function fetchData() {
        setIsLoading(true);
        try {
          const data = !!func.payload
            ? await func.api(func.payload)
            : await func.api();
          setPagination((p) => ({ ...p, total: data?.length }));
          await setCurrentPageData(data, pagination);
          setTableData(data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }

      fetchData();

      return () => {
        fetchData();
      };
    }
  }, [setIsLoading, func]);

  function handleTabChange(pagination, filters, sorter) {
    setPagination({
      ...pagination,
    });
    setTableChange({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    setCurrentPageData(tableData, pagination);
  }

  const rowSelection = {
    onChange: (_, selectedRows) => {
      if (setSelectedData) {
        setSelectedData(selectedRows);
      }
    },
  };

  return (
    <TableStyles>
      <OverFlowScrollBar>
        <Table
          dataSource={tableData}
          columns={columns}
          noShadow={true}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTabChange}
          scroll={{ x: maxWidth || 1500 }}
          rowSelection={setSelectedData ? rowSelection : undefined}
          rowKey={(record) => setSelectedData && record._id}
        />
      </OverFlowScrollBar>
    </TableStyles>
  );
};

export default CustomTable;
