import React, { useState, useContext } from "react";
import { LoginStylesWrap } from "./login.styles";
import { Section, FlexibleDiv } from "../../components/Box/styles";
import LoginImage from "../../assets/Img/LoginImages/login-image.png";
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import Logo from "../../assets/Img/LoginImages/logo.svg";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Form, notification } from "antd";
import { CURRENT_USER } from "../../context/types";
import { MainContext } from "../../context/MainContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(MainContext);
  const navigate = useNavigate()

  const handleFormSubmit = (values) => {
    setIsLoading(true);

    const payload = {
      ...values,
      username: values.username,
    };
    axios
      .post(`https://prospect-api.poprev.dev/api/user/login`, {
        ...payload,
      })
      .then((response) => {
        localStorage.setItem("admin_token", response?.data?.access_token);
        localStorage.setItem("refresh_token", response?.data?.refresh_token);
        dispatch({ payload: response?.data?.user, type: CURRENT_USER });
        setIsLoading(false);

        // navigate('/')
        //the context is gone when we use window.location
        window.location.replace("/");
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);
          notification.open({
            message: "Error",
            description: "Username or Email not Found",
            icon: <SmileOutlined style={{ color: "red" }} />,
          });
          setIsLoading(false);
        } else {
          notification.open({
            message: "Error",
            description:
              "There was an issue with your network. Pls try again later",
            icon: <SmileOutlined style={{ color: "red" }} />,
          });
          setIsLoading(false);
        }
      });
  };

  return (
    <LoginStylesWrap>
      <FlexibleDiv height="100%" flexWrap="nowrap">
        <Section className="login__leftSection" width="47%" height="100%">
          <img src={LoginImage} alt="" />
        </Section>
        <Section
          className="login__rightSection"
          width="53%"
          height="100%"
          justifyContent="flex-start"
        >
          <FlexibleDiv justifyContent="flex-end">
            <img src={Logo} alt="" />
          </FlexibleDiv>
          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            width="90%"
            height="90%"
            className="formWrap"
            flexWrap="nowrap"
          >
            <h2>Draft Pick Platform</h2>
            <p>
              If you have this link we don’t need to explain why you’re here…
            </p>
            <Form form={form} onFinish={handleFormSubmit}>
              <TextField name="username" placeholder="username" />
              <TextField
                name="password"
                placeholder="password"
                type="password"
              />
              <Button type="submit">
                {isLoading && <LoadingOutlined />} &nbsp; LOG IN
              </Button>
            </Form>
          </FlexibleDiv>
        </Section>
      </FlexibleDiv>
    </LoginStylesWrap>
  );
};

export default Login;
