import axios from "axios";

let adminToken = localStorage.getItem("admin_token");
let refreshToken = localStorage.getItem("refresh_token");

export const instance = axios.create({
  baseURL: "https://prospect-api.poprev.dev/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (config) => {
    if (adminToken) {
      config.headers["Authorization"] = `Bearer ${adminToken}` || null; // for Spring Boot back-end
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const newToken = await instance.post("/auth/refresh", {
            refreshToken: refreshToken,
          });
          const { loginToken } = newToken.data;
          localStorage.setItem("admin_token", loginToken);
          adminToken = loginToken;
          return await instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 429 && !originalConfig._logout) {
        originalConfig._logout = true;
        localStorage.removeItem("admin_token");
        localStorage.removeItem("refresh_token");
        window.location.pathname = "/logout";
      }
    }
    return Promise.reject(err);
  }
);
