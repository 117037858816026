import React, { useContext, useState, useRef } from "react";
import DashboardLayout from "../../../components/Layout/layout";
import { Section, FlexibleDiv } from "../../../components/Box/styles";
import { ArtistWrap } from "./artist.styles";
import CustomTable from "../../../components/Table/table";
import { MainContext } from "../../../context/MainContext";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";

const Artists = () => {
  const [tableChange, setTableChange] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  const {
    state: { artistApi },
  } = useContext(MainContext);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getAgeSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="search_input"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="search_button"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="reset_button"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    filters: [{ text: searchText, value: searchText }],
    filteredValue: tableChange?.filteredInfo?.age || null,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getCountrySearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="search_input"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="search_button"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="reset_button"
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    filters: [{ text: searchText, value: searchText }],
    filteredValue: tableChange?.filteredInfo?.country_of_residence || null,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Artist</h3>
        </FlexibleDiv>
      ),
      dataIndex: "moniker",
      key: "moniker",
      sorter: (a, b) => a.moniker.length - b.moniker.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "moniker" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Profile</h3>
        </FlexibleDiv>
      ),
      dataIndex: "artist_type",
      key: "artist_type",
      filters: [
        { text: "Label", value: "Label" },
        { text: "Artist", value: "Artist" },
        { text: "Manager", value: "Manager" },
      ],
      filteredValue: tableChange?.filteredInfo?.artist_type || null,
      onFilter: (value, artist) =>
        artist?.projects[0]?.artist_status?.artist_type.includes(value),
      render: (arr, artist) => (
        <></>
        // <p
        //   className={
        //     artist?.projects[0]?.artist_status?.artist_type === "Label"
        //       ? "profile label"
        //       : artist?.projects[0].artist_status?.artist_type === "Manager"
        //       ? "profile manager"
        //       : "profile artist"
        //   }
        // >
        //   {artist?.projects[0].artist_status?.artist_type}
        // </p>
      ),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Age</h3>
        </FlexibleDiv>
      ),
      dataIndex: "age",
      key: "age",
      ...getAgeSearchProps("age"),
      sorter: (a, b) => a.age - b.age,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "age" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Gender</h3>
        </FlexibleDiv>
      ),
      dataIndex: "gender",
      key: "gender",
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
        { text: "Non-Binary", value: "Non-Binary" },
      ],
      filteredValue: tableChange?.filteredInfo?.gender || null,
      onFilter: (value, artist) => artist?.gender.includes(value),
      render: (arr, artist) => (
        <p
          className={
            artist?.gender === "Female"
              ? "gender female"
              : artist?.gender === "Male"
              ? "gender male"
              : "gender non"
          }
        >
          {artist?.gender}
        </p>
      ),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Projects</h3>
        </FlexibleDiv>
      ),
      dataIndex: "projects",
      key: "projects",
      sorter: (a, b) => a.projects.length - b.projects.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "projects" &&
        tableChange?.sortedInfo?.order,
      render: (arr, artist) => (
        <p className="alignText">{artist?.projects?.length}</p>
      ),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Approved</h3>
        </FlexibleDiv>
      ),
      dataIndex: "approved_projects",
      key: "approved_projects",
      // ...getColumnSearchProps("approved_projects"),
      sorter: (a, b) => a.approved_projects - b.approved_projects,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "approved_projects" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Rejected</h3>
        </FlexibleDiv>
      ),
      dataIndex: "rejected_projects",
      key: "rejected_projects",
      // ...getColumnSearchProps("rejected_projects"),
      sorter: (a, b) => a.rejected_projects - b.rejected_projects,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "rejected_projects" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Pending</h3>
        </FlexibleDiv>
      ),
      dataIndex: "pending_projects",
      key: "pending_projects",
      // ...getColumnSearchProps("pending_projects"),
      sorter: (a, b) => a.pending_projects - b.pending_projects,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "pending_projects" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Artist Genre</h3>
        </FlexibleDiv>
      ),
      dataIndex: "genre",
      key: "genre",
      filters: [
        { text: "RAP", value: "RAP" },
        { text: "AFROBEATS", value: "AFROBEATS" },
        { text: "DANCEHALL", value: "DANCEHALL" },
        { text: "AFROHOUSE", value: "AFROHOUSE" },
        { text: "ROCK/PUNK", value: "ROCK/PUNK" },
        { text: "ALTE VIBES", value: "ALTE VIBES" },
        { text: "FUSION", value: "FUSION" },
        { text: "HIGHLIFE", value: "HIGHLIFE" },
        { text: "JUJU", value: "JUJU" },
        { text: "FUJI", value: "FUJI" },
        { text: "ELECTRONIC", value: "ELECTRONIC" },
      ],
      filteredValue: tableChange?.filteredInfo?.genre || null,
      onFilter: (value, artist) =>
        artist?.projects[0]?.project_genre?.includes(value),
      render: (arr, artist) => (
        <FlexibleDiv>
          {artist?.projects[0]?.project_genre?.map((project, id) => (
            <span key={id}>
              {project}
              {id + 1 < artist?.projects[0]?.project_genre?.length && ","}{" "}
              &nbsp;
            </span>
          ))}
        </FlexibleDiv>
      ),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Location</h3>
        </FlexibleDiv>
      ),
      dataIndex: "country_of_residence",
      key: "country_of_residence",
      ...getCountrySearchProps("country_of_residence"),

      sorter: (a, b) =>
        a.country_of_residence.length - b.country_of_residence.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "country_of_residence" &&
        tableChange?.sortedInfo?.order,
    },
  ];

  return (
    <DashboardLayout>
      <ArtistWrap>
        <Section
          flexDir="column"
          alignItems="flex-start"
          className="artistContent__wrap"
        >
          <h3>Artists</h3>

          <CustomTable
            columns={columns}
            maxWidth={1200}
            func={artistApi}
            setTableChange={setTableChange}
          />
        </Section>
      </ArtistWrap>
    </DashboardLayout>
  );
};

export default Artists;
