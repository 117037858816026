import styled from "styled-components";

export const HeaderStyles = styled.div`
  width: calc(100vw - 200px);
  height: 70px;
  position: fixed;
  right: 0;
  z-index: 9;

  .ant-layout-header {
    background: #1d7874;
    height: 100%;
    padding: 0 20px;
    form {
      width: 70%;
    }

    .userDetails__wrap {
      svg,
      h3 {
        color: #fff;
        margin: 0;
        white-space: nowrap;
      }

      svg {
        font-size: 30px;
      }
    }
  }
`;
