import React from "react";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { FlexibleDiv } from "../../../../components/Box/styles";
import Button from "../../../../components/Button";
import { genreFilter } from "../../../../utils/dataHelpers/filter";
import { BiCircle as Circle } from "react-icons/bi";
import { IoMdArrowBack as BackIcon } from "react-icons/io";

const GenreModal = ({
  showGenreModal,
  setShowGenreModal,
  setReviewerGenre,
  reviewer_genre,
}) => {
  const handleGenre = (genre) => {
    if (reviewer_genre.includes(genre)) {
      reviewer_genre.splice(reviewer_genre.indexOf(genre), 1);
      setReviewerGenre([...reviewer_genre]);
      return;
    } else {
      if (reviewer_genre.length < 4) {
        setReviewerGenre([...reviewer_genre, genre]);
      }
    }
  };

  const cancel = () => {
    setReviewerGenre([]);
    setShowGenreModal();
  };

  const close = () => {
    if (reviewer_genre.length > 1) {
      setShowGenreModal();
    }
  };
  return (
    <ModalWrapper
      title=""
      visible={showGenreModal}
      onCancel={() => {
        cancel();
      }}
      footer={null}
      centered={true}
      closable={false}
      maxWidth="400px"
    >
      <FlexibleDiv className="header">
        <FlexibleDiv className="back" onClick={cancel}>
          <BackIcon />
        </FlexibleDiv>
        <h3>Reviewer Categorisation</h3>
      </FlexibleDiv>
      <FlexibleDiv justifyContent="space-between">
        {genreFilter.map((genre, idx) => (
          <FlexibleDiv
            justifyContent="flex-start"
            width="50%"
            onClick={() => handleGenre(genre)}
            className={
              reviewer_genre.includes(genre)
                ? "activeGenre genreItems"
                : "genreItems"
            }
            key={genre + idx}
          >
            <Circle />
            <span>{genre}</span>
          </FlexibleDiv>
        ))}
      </FlexibleDiv>
      <FlexibleDiv>
        <Button onClick={close}>Submit</Button>
      </FlexibleDiv>
    </ModalWrapper>
  );
};

export default GenreModal;
