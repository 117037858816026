import styled from "styled-components";

export const DateFilterWrap = styled.div`
  padding: 5px 30px;
  background: #679289;
  border: 1px solid #fff;

  small {
    font-size: 10px;
    color: #fff;
    margin-bottom: 2px;
  }
  .ant-picker {
    margin-right: 20px;
  }
  input::placeholder {
    color: #000;
    font-size: 13px;
  }
  input {
    font-size: 13px;
  }
  .ant-picker-suffix {
    svg {
      color: #1d7874;
    }
  }
  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    .ant-picker-input {
      pointer-events: none !important;
      cursor: not-allowed !important;
    }
  }
`;
