import {
  CURRENT_USER,
  ARTIST_API,
  PENDING_API,
  PROJECT_API,
  REJECTED_API,
  APRROVED_API,
  PAGE_DATA,
} from "./types";
import { getAllArtists } from "../network/artist";
import {
  getAllApprovedProjects,
  getAllPendingProjects,
  getAllProjects,
  getAllRejectedProjects,
} from "../network/projects";

export const Reducer = (state, { type, payload }) => {
  switch (type) {
    case CURRENT_USER:
      return {
        ...state,
        user: payload || undefined,
      };

    case ARTIST_API:
      return {
        ...state,
        artistApi: payload || getAllArtists,
      };

    case PROJECT_API:
      return {
        ...state,
        projectApi: payload || getAllProjects,
      };
    case PENDING_API:
      return {
        ...state,
        pendingApi: payload || getAllPendingProjects,
      };
    case REJECTED_API:
      return {
        ...state,
        rejectedApi: payload || getAllRejectedProjects,
      };
    case APRROVED_API:
      return {
        ...state,
        approvedApi: payload || getAllApprovedProjects,
      };
    case PAGE_DATA:
      return {
        ...state,
        pageData: payload || [],
      };

    default:
      return state;
  }
};
