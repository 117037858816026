import styled from "styled-components";

export const ScorecardWrap = styled.div`
  padding: 0 0 50px 0;
  background: transparent;
  .navigation__wrap {
    padding: 10px;
    border-bottom: 1px solid #707070;

    .close {
      cursor: pointer;
      svg {
        font-size: 20px;
      }
    }
  }

  .body__wrap {
    padding: 0 10px;
    ovverflow-y: auto;
    overflow-x: scroll;
    justify-content: flex-start;

    .body__wrap__sub {
      background: #071e22;
      margin-top: -5px;
      min-width: 1350px;
      .projectDetails {
        .projectDetails__top {
          padding: 30px;
          background: #1d7874;
          border-bottom: 1px solid #fff;

          .projecName__wrap {
            background: #071e22;
            padding: 20px 30px;
            min-width: 170px;
            span {
              margin: 10px 0;
              color: #8f8f8f;
            }
          }

          .projectContent__wrap {
            margin-left: 20px;
            width: 70%;
            max-width: 400px;
            p {
              color: #fff;
              min-width: 150px;
              text-align: left;
              margin: 7px 0;
            }
          }
          .socials__wrap {
            svg {
              color: #fff;
              font-size: 17px;
              margin-right: 10px;
            }
            .no_link_svg {
              color: #eef5db;
              font-size: 17px !important;
            }
          }
        }

        .projectDetails__bottom {
          border-right: 1px solid #707070;

          & > div {
            padding: 20px 20px 0 20px;
            border-top: 1px solid #707070;

            h4 {
              color: #eef5db;
              font-size: 16px;
            }

            span,
            small,
            a {
              font-size: 14px;
              margin-bottom: 20px;
              width: 20%;
              text-align: left;
            }
            span {
              color: #fff;
            }
            small,
            a {
              color: #eef5db;
            }
            a {
              text-decoration: underline;
            }
            .play {
              margin-bottom: 20px;
              svg {
                color: #eef5db;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .nominatedGenres {
      min-height: 50px;
      padding: 10px 20px 0 20px;
      span {
        color: #fff;
        margin: 10px 0;
      }
      small {
        color: #eef5db;
        margin-right: 10px;
        padding: 5px 18px;
        background: #679289;
        border-radius: 6px;
        font-size: 12px;
      }
    }
    .edit {
      cursor: pointer;
      margin-top: 4px;
      font-size: 14px;
    }
    #edit_active {
      font-size: 16px;
      color: green;
      margin-top: 2px;
    }
    .plus {
      font-size: 16px;
      cursor: pointer;
    }
    .reviewSection__top {
      padding: 40px 20px 20px 20px;
      background: #eef5db;
      border: 1px solid #707070;

      h4 {
        margin: 0;
      }
      .projectStatus {
        span {
          color: #926767;
        }
      }
      .musicLink {
        margin: 20px 0;
        svg {
          font-size: 30px;
        }
        a {
          color: #071e22;
          text-decoration: underline;
          font-size: 12px;
        }
      }
      .ratingsSection {
        margin: 5px 0;
        span {
          font-size: 12px;
        }
      }
      .categories {
        min-height: 50px;
        span {
          color: #fff;
          margin-right: 10px;
          padding: 5px 18px;
          background: #071e22;
          border-radius: 6px;
          font-size: 12px;
        }
      }
    }

    .projectDetails__footer {
      & > div {
        padding: 20px;
        border-top: 1px solid #707070;

        span {
          color: #fff;
          font-size: 14px;
          width: 20%;
          text-align: left;
          margin: 0 0 10px 0;
        }

        h4 {
          width: 20%;
          line-height: 0;
          font-size: 14px;
          margin: 0 0 10px 0;
          color: #eef5db;
        }
      }
      .footer__left {
        border-right: 1px solid #707070;
        align-items: flex-start;
        h4 {
          margin-top: 10px;
        }
      }
      .footer__right {
        span {
          color: #eef5db;
        }
      }
    }
  }
  button:disabled,
  button[disabled] {
    background-color: #ddd !important;
    cursor: not-allowed;
  }
`;
