import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Projects from "./pages/Dashboard/Projects/projects";
import Review from "./pages/Dashboard/Review/review";
import Rejected from "./pages/Dashboard/Rejected/rejected";
import Accepted from "./pages/Dashboard/Accepted/accepted";
import Login from "./pages/Login/login";
import ScrollToTop from "./components/ScrollManager";
import Scorecard from "./pages/Dashboard/ProjectScorecard/scorecard";
import "antd/dist/antd.min.css";
import Dashboard from "./pages/Dashboard/Artists/artist";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/projects/review" element={<Review />} />
            <Route path="/projects/rejected" element={<Rejected />} />
            <Route path="/projects/accepted" element={<Accepted />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/project/:id" element={<Scorecard />} />
            <Route path="/" element={<Dashboard />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
