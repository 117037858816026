import React from "react";
import { FlexibleDiv } from "../Box/styles";
import { StarsWrap } from "./star.styles";
import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";

export const TableStars = ({ rate }) => {
  const star = [1, 2, 3, 4, 5];
  return (
    <StarsWrap>
      <FlexibleDiv justifyContent="space-between">
        {star.map((item, idx) =>
          rate >= item ? (
            <AiTwotoneStar key={idx} />
          ) : (
            <AiOutlineStar key={idx} />
          )
        )}
      </FlexibleDiv>
    </StarsWrap>
  );
};

const RatingStars = ({ rate, small, clickable, setScore }) => {
  const star = [1, 2, 3, 4, 5];

  return (
    <StarsWrap small>
      <FlexibleDiv justifyContent="space-between">
        {star.map((item, idx) => (
          <AiTwotoneStar
            className={rate < item && "dark"}
            id={!!clickable && "clickable"}
            onClick={() => clickable && setScore(item)}
            key={idx}
          />
        ))}
      </FlexibleDiv>
    </StarsWrap>
  );
};

export default RatingStars;
