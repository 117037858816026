import React from "react";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { FlexibleDiv } from "../../../../components/Box/styles";
import Button from "../../../../components/Button";
import { rejectionReason } from "../../../../utils/dataHelpers/filter";
import { BiCircle as Circle } from "react-icons/bi";
import { IoMdArrowBack as BackIcon } from "react-icons/io";

const RejectionReason = ({
  reason_for_status,
  setReviewReason,
  showRejectionModal,
  setShowRejectionModal,
  setReviewStatus,
  setShowSubmitModal,
}) => {
  const handleReason = (reason) => {
    setReviewReason(reason);
  };

  const cancel = () => {
    setReviewReason();
    setReviewStatus();
    setShowRejectionModal();
  };

  const close = () => {
    if (!!reason_for_status) {
      setShowRejectionModal();
      setShowSubmitModal(true);
    } else {
      return;
    }
  };

  return (
    <ModalWrapper
      title=""
      visible={showRejectionModal}
      onCancel={() => {
        cancel();
      }}
      footer={null}
      centered={true}
      closable={false}
      maxWidth="400px"
    >
      <FlexibleDiv className="header">
        <FlexibleDiv className="back" onClick={cancel}>
          <BackIcon />
        </FlexibleDiv>
        <h3>They Didn’t make the Cut 🤭</h3>
      </FlexibleDiv>
      <FlexibleDiv flexDir="column" alignItems="flex-start">
        {rejectionReason.map((reason, idx) => (
          <FlexibleDiv
            justifyContent="space-between"
            onClick={() => handleReason(idx + 1)}
            className={
              reason_for_status === idx + 1 ? "active radioItems" : "radioItems"
            }
            key={reason + idx}
          >
            <span>{reason}</span>
            <Circle />
          </FlexibleDiv>
        ))}
      </FlexibleDiv>
      <FlexibleDiv>
        <Button onClick={close}>Submit</Button>
      </FlexibleDiv>
    </ModalWrapper>
  );
};

export default RejectionReason;
