import styled from "styled-components";

export const SumTotalWrap = styled.div`
  width: 100%;
  padding: 10px 0;
  background: #eef5db;

  .card {
    width: 210px;
    padding: 20px 10px;
    height: 80px;
    background: #ffffff;
    border: 1px solid #1d7874;
    margin: 10px 20px;

    p {
      font-size: 20px;
      margin-bottom: 0;
    }
    small {
      color: #707070;
    }
    .cardIcon {
      padding: 10px;
      border-radius: 10px;
      margin-right: 8px;

      svg {
        font-size: 20px;
        color: #fff;
      }
    }
    .pending {
      background: #fde758;
    }
    .accepted {
      background: #21ae00;
    }
    .artist {
      background: #cc00b1;
    }
    .rejected {
      background: #d8211e;
    }
  }
`;
