import styled from "styled-components";

export const StarsWrap = styled.div`
  width: 100%;

  svg {
    color: #679289;
    fontsize: ${({ small }) => (small ? "18px" : "22px")};
  }
  .dark {
    color: #000;
  }
  #clickable {
    cursor: pointer;
  }
`;
