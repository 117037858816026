import React from "react";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { FlexibleDiv } from "../../../../components/Box/styles";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack as BackIcon } from "react-icons/io";

const CloseScoreCard = ({
  setShowCloseCardModal,
  showCloseCardModal,
  changeCard,
  handleNextScoreCard,
  handlePrevScoreCard,
  setchangeCard,
}) => {
  const navigate = useNavigate();

  const closeScoreCard = () => {
    if (changeCard === "") {
      navigate("/projects");
    } else if (changeCard === "next") {
      handleNextScoreCard();
    } else if (changeCard === "prev") {
      handlePrevScoreCard();
    }
    setShowCloseCardModal(false);
  };

  const closeModal = () => {
    setShowCloseCardModal(false);
    setchangeCard("");
  };
  return (
    <ModalWrapper
      title=""
      visible={showCloseCardModal}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      centered={true}
      closable={false}
    >
      <FlexibleDiv className="header">
        <FlexibleDiv className="back" onClick={closeModal}>
          <BackIcon />
        </FlexibleDiv>
        <h3>THINK CAREFULLY! 😂</h3>
      </FlexibleDiv>
      <FlexibleDiv flexDir="column">
        <p className="bodyText">
          If you leave now, you’ll lose your progress so far 🥲
        </p>
        <Button onClick={() => closeScoreCard(false)}>
          I Know What I’m Doing
        </Button>
      </FlexibleDiv>
    </ModalWrapper>
  );
};

export default CloseScoreCard;
