export const reviewReason = (status, score) => {
  const approvalReason = [
    "Project is Perfect",
    "The project needs some Tweaks",
    "I love the project - release concerns",
  ];
  const rejectionReason = [
    "Project is not ready (too much work to fix)",
    "Artist has potential but not right now",
    "Not quite what we’re looking for",
    "It doesn't align with our release plans for the year",
  ];
  if (status === "Approved") {
    return approvalReason[score - 1];
  }
  if (status === "Rejected") {
    return rejectionReason[score - 1];
  }
};
