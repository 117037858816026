import React, { useEffect } from "react";
import { Layout } from "antd";
import DashboardHeader from "./Header/header";
import { DashboardLayoutStyles } from "./layout.styles";
import DashboardSideBar from "./Sidebar/sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import DateFilter from "../DatePicker/datePicker";
import SumTotal from "../SumTotalCards/sumTotal";

const DashboardLayout = ({ children }) => {
  const { Content } = Layout;
  const navigate = useNavigate();
  const location = useLocation();
  const routes = [
    "/",
    "/projects",
    "/projects/accepted",
    "/projects/rejected",
    "/projects/review",
  ];

  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    if (!token) navigate("/login");
  }, [navigate]);

  return (
    <DashboardLayoutStyles>
      <Layout>
        <DashboardHeader />
        <DashboardSideBar />
        <Content>
          <>
            {routes.includes(location.pathname) && (
              <>
                <SumTotal />
                <DateFilter />
              </>
            )}
          </>

          {children}
        </Content>
      </Layout>
    </DashboardLayoutStyles>
  );
};

export default DashboardLayout;
