import { instance } from "./axios";

export const loginUser = async (payload) => {
  const { data } = await instance.post("/user/login", payload);
  return data;
};

export const getCuurentUser = async () => {
  const { data } = await instance.get("user/current");
  return data;
};
