import React, { useContext, useState } from "react";
import DashboardLayout from "../../../components/Layout/layout";
import { Section, FlexibleDiv } from "../../../components/Box/styles";
import { RejectedWrap } from "./rejected.styles";
import CustomTable from "../../../components/Table/table";
import { TableStars } from "../../../components/RatingStar/ratingStar";
import { MainContext } from "../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { reviewReason } from "../../../utils/fuctionHelpers";
import moment from "moment";

const Rejecteds = () => {
  const {
    state: { rejectedApi },
  } = useContext(MainContext);

  const navigate = useNavigate();

  const [tableChange, setTableChange] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  const columns = [
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>P.ID</h3>
        </FlexibleDiv>
      ),
      dataIndex: "p.id",
      key: "p.id",
      render: (arr, project) => (
        <p
          className="content_id"
          onClick={() =>
            navigate(`/project/${project._id}`, {
              state: { ...project },
            })
          }
        >
          {project?._id}
        </p>
      ),
      sorter: (a, b) => a._id.length - b._id.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "p.id" &&
        tableChange?.sortedInfo?.order,
    },

    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Project Name</h3>
        </FlexibleDiv>
      ),
      dataIndex: "project",
      key: "project",
      render: (arr, project) => project?.project_name,
      sorter: (a, b) => a.project_name.length - b.project_name.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "project" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Artist</h3>
        </FlexibleDiv>
      ),
      dataIndex: "artist",
      key: "artist",
      render: (arr, project) => project?.project_owner?.moniker,
      sorter: (a, b) =>
        a.project_owner.moniker.length - b.project_owner.moniker.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "artist" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Release</h3>
        </FlexibleDiv>
      ),
      dataIndex: "release",
      key: "release",
      render: (arr, project) => project?.project_status?.release_date,
      sorter: (a, b) =>
        moment(a.project_status.release_date).unix() -
        moment(b.project_status.release_date).unix(),
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "release" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Type</h3>
        </FlexibleDiv>
      ),
      dataIndex: "project_type",
      key: "project_type",
      render: (arr, project) => (
        <p
          className={
            project?.project_type === "EP"
              ? "ep__type projectType"
              : "lp__type projectType"
          }
        >
          {project?.project_type}
        </p>
      ),
      filters: [
        { text: "ALBUM", value: "ALBUM" },
        { text: "EP", value: "EP" },
      ],
      filteredValue: tableChange?.filteredInfo?.project_type || null,
      onFilter: (value, project) => project?.project_type.includes(value),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Reviewer</h3>
        </FlexibleDiv>
      ),
      dataIndex: "reviewer",
      key: "reviewer",
      render: (arr, project) => (
        <p className={!project?.review[0].reviewed_by?.username && "reviewer"}>
          {project?.review[0].reviewed_by?.username || "None"}
        </p>
      ),
      sorter: (a, b) =>
        a.review[0].reviewed_by?.username?.length -
        b.review[0].reviewed_by?.username?.length,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "reviewer" &&
        tableChange?.sortedInfo?.order,
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Artist Genre</h3>
        </FlexibleDiv>
      ),
      dataIndex: "genre",
      key: "genre",
      render: (arr, project) => (
        <FlexibleDiv>
          {project.project_genre.map((item, id) => (
            <span key={id}>
              {item}
              {id + 1 < project?.project_genre?.length && ","} &nbsp;
            </span>
          ))}
        </FlexibleDiv>
      ),
      filters: [
        { text: "RAP", value: "RAP" },
        { text: "RNB", value: "RNB" },
        { text: "AFROBEATS", value: "AFROBEATS" },
        { text: "DANCEHALL", value: "DANCEHALL" },
        { text: "AFROHOUSE", value: "AFROHOUSE" },
        { text: "ROCK/PUNK", value: "ROCK/PUNK" },
        { text: "ALTE VIBES", value: "ALTE VIBES" },
        { text: "FUSION", value: "FUSION" },
        { text: "HIGHLIFE", value: "HIGHLIFE" },
        { text: "JUJU", value: "JUJU" },
        { text: "FUJI", value: "FUJI" },
        { text: "ELECTRONIC", value: "ELECTRONIC" },
      ],
      filteredValue: tableChange?.filteredInfo?.genre || null,
      onFilter: (value, project) => project?.project_genre?.includes(value),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Rating</h3>
        </FlexibleDiv>
      ),
      dataIndex: "rating",
      key: "rating",
      render: (arr, project) =>
        !!project?.review[0].scorecard?.overall ? (
          <TableStars rate={project?.review[0]?.scorecard?.overall} />
        ) : (
          <p className="notRated">Not Rated</p>
        ),

      sorter: (a, b) =>
        a.review[0]?.scorecard?.overall - b.review[0]?.scorecard?.overall,
      sortOrder:
        tableChange?.sortedInfo?.columnKey === "rating" &&
        tableChange?.sortedInfo?.order,
    },

    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Outcome</h3>
        </FlexibleDiv>
      ),
      dataIndex: "Outcome",
      key: "Outcome",
      render: (arr, project) => (
        <p className="outcome rejected">{project?.review[0].review_status}</p>
      ),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Reason</h3>
        </FlexibleDiv>
      ),
      dataIndex: "reason",
      key: "reason",
      render: (arr, project) =>
        reviewReason(
          project?.review[0].review_status,
          project?.review[0].reason_for_status
        ) || "None",
      filters: [
        {
          text: "Project is not ready (too much work to fix)",
          value: "Project is not ready (too much work to fix)",
        },
        {
          text: "Artist has potential but not right now",
          value: "Artist has potential but not right now",
        },
        {
          text: "Not quite what we’re looking for",
          value: "Not quite what we’re looking for",
        },
        {
          text: "It doesn't align with our release plans for the year",
          value: "It doesn't align with our release plans for the year",
        },
        { text: "Project is Perfect", value: "Project is Perfect" },
        {
          text: "The project needs some Tweaks",
          value: "The project needs some Tweaks",
        },
        {
          text: "I love the project - release concerns",
          value: "I love the project - release concerns",
        },
      ],
      filteredValue: tableChange?.filteredInfo?.reason || null,
      onFilter: (value, project) =>
        reviewReason(
          project?.review[0].review_status,
          project?.review[0].reason_for_status
        ).includes(value),
    },
    {
      title: (
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-between">
          <h3>Project Phase</h3>
        </FlexibleDiv>
      ),
      dataIndex: "phase",
      key: "phase",
      render: (arr, project) => project?.project_status?.project_dev_phase,
      filters: [
        {
          text: "Conceptualisation",
          value: "Conceptualisation",
        },
        {
          text: "Still working on tracks",
          value: "Still working on tracks",
        },
        {
          text: "Requires mix & master",
          value: "Requires mix & master",
        },
        {
          text: "Ready to go",
          value: "Ready to go",
        },
      ],
      filteredValue: tableChange?.filteredInfo?.phase || null,
      onFilter: (value, project) =>
        project?.project_status?.project_dev_phase.includes(value),
    },
  ];

  return (
    <DashboardLayout>
      <RejectedWrap>
        <Section
          flexDir="column"
          alignItems="flex-start"
          className="rejectedContent__wrap"
        >
          <h3>Rejected</h3>

          <CustomTable
            columns={columns}
            func={rejectedApi}
            setTableChange={setTableChange}
          />
        </Section>
      </RejectedWrap>
    </DashboardLayout>
  );
};

export default Rejecteds;
